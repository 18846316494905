import React from 'react';
import { Modal } from "@huksley/react-responsive-modal";
import "@huksley/react-responsive-modal/styles.css";

import './Contact.css'
import { Redirect } from 'react-router-dom';

class Contact extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            open: false,
        };
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {
        this.onOpenModal();

        const navbar = document.getElementById('navbar');
        navbar.style['visibility'] = 'collapse';
    }

    getMessagesFromDatabase() { }

    // modal functions
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
        window.location.href = '/';
    };

    async executeSubmit() {

    const parameter =
    {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message
    }
    return fetch('/api/v1/Contact/message', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(parameter)
        })
        .then(data => data.json())
        .then(() => {
            window.location.href = "/";
        })
    }

    handleChangeName(event)
    {
        this.setState({ name: event.target.value });
    }


    handleChangeEmail(event) {
        this.setState({ email: event.target.value });
    }

    handleChangeMessage(event) {
        this.setState({ message: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.executeSubmit();
    }

	render() {

        const { open } = this.state;

		return (
            <Modal open={open} onClose={this.onCloseModal} className="Modal">
		        <article id="contact">
			        <h2 className="major">Contact</h2>
                    <form action="/" onSubmit={this.handleSubmit}>
				        <div className="fields">
					        <div className="field half">
						        <label htmlFor="name">Name</label>
                                <input type="text" name="name" id="name" value={this.state.name} onChange={this.handleChangeName} />
					        </div>
					        <div className="field half">
						        <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" value={this.state.email} onChange={this.handleChangeEmail} />
					        </div>
					        <div className="field">
						        <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" rows="4" value={this.state.message} onChange={this.handleChangeMessage}></textarea>
					        </div>
				        </div>
				        <ul className="actions">
					        <li><input type="submit" value="Send Message" className="primary" /></li>
					        <li><input type="reset" value="Reset" /></li>
				        </ul>
			        </form>
			        <ul className="icons">
				        <li><a href="#" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
				        <li><a href="#" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
				        <li><a href="#" className="icon brands fa-instagram"><span className="label">Instagram</span></a></li>
				        <li><a href="#" className="icon brands fa-github"><span className="label">GitHub</span></a></li>
			        </ul>
                </article>
               
            </Modal>
		);
	}
}

export default Contact;