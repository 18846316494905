import React, { useEffect, useState } from 'react';
import './Home.css'

async function loadweatherData() {
	return fetch('/api/v1/WeatherForecast', {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	})
		.then(data => data.json())
}

class Home extends React.Component {

	constructor(props)
	{
		super(props);

		this.state =
		{
			count: 0,
			data: "test",
		};
    }

	componentDidMount() {
		const main = document.getElementById('main');
		setTimeout(function () { main.classList.replace('is-preload', 'is-postload'); }, 100);

		const navbar = document.getElementById('navbar');
		navbar.style['visibility'] = 'collapse';
	}

	onClick = async e => {
		e.preventDefault();

		var response = await loadweatherData();
		var result = JSON.stringify(response);
		this.setState({ data: this.state.coudatant = result })
		//console.log(data.toString());
	}

	render() {
		return (
			<div className="is-preload" id="main">
			
			<div id="wrapper">
				
				<header id="header">
					<div className="logo">
						<span className="fas fa-wrench"></span>
					</div>
					<div className="content">
						<div className="inner">
							<h1>VBTEK</h1>
							<p></p>
						</div>
					</div>
					<nav>
						<ul>
							{/*<!--*/}
							{/*<li><a href="#intro">Intro</a></li>*/}
							{/*<li><a href="#work">Work</a></li>*/}
							{/*<li><a href="#about">About</a></li>*/}
							{/*-->*/}
							{<li><a href="Contact">Contact</a></li>}
							{/*<li><button onClick={() => this.setState({ count: this.state.count + 1 })}>Contact</button ></li>*/}
							{/*<li><button onClick={this.onClick}>Contact</button ></li>*/}
							{/*<!--*/}
							{/*<li><a href="#elements">Elements</a></li>*/}
							{/*-->*/}
						</ul>
					</nav>
				</header>

				{/*<h1>{this.state.count}</h1>*/}
				{/*<p>{this.state.data}</p>*/}
				<div id="main">

					{/*<!-- Intro -->*/}
					{/*<!--#include virtual="src/intro.shtml" -->*/}

					{/*<!-- Work -->*/}
					{/*<!--#include virtual="src/work.shtml" -->*/}

					{/*<!-- About -->*/}
					{/*<!--#include virtual="src/about.shtml" -->*/}

					{/*<!-- Contact -->*/}
					{/*<!--#include virtual="src/contact.shtml" -->*/}

					{/*<!-- Elements -->*/}
					{/*<!--#include virtual="src/elements.shtml" -->	*/}

				</div>


				<footer id="footer">
					<p className="copyright">&copy; Untitled. Design: <a href="https://vbtek.de">vbtek</a>.</p>
				</footer>


				</div>
				<div className="bg" id="bg" />
			</div>
		);
	}
}

export default Home;
