import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";

export default function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />} >
                <Route index element={<Home />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/*" element={<Home />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
